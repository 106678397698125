.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--figma-color-line-gray);
  color: var(--figma-color-sideMenuText);
  background-color: var(--figma-color-localSideMenu);
  position: relative;
  font-size: 14px;
}
.root:not(.isResizing) {
  transition: width 0.2s ease;
}
.rootResizeHandler {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  user-select: none;
  z-index: 1;
}
.root:not(.isCollapsed) .rootResizeHandler {
  cursor: col-resize;
}

.root .firstMenu {
  height: max-content;
  min-height: 64px;
  font-size: 14px;
  padding: 14px 15px 12px 20px;
}
.root .firstMenu .content {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 8px;
  border-radius: 10px;
}
.root .firstMenu .content:hover {
  background-color: var(--figma-color-sideMenuHover);
  cursor: pointer;
}
.root .firstMenu .content .textWithTooltip {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}
.root .firstMenu .content .textWithTooltip .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
  height: 22px;
}
.root .firstMenu .content .rightButtons {
  margin-left: 7px;
  margin-right: 0;
  display: flex;
  justify-content: space-between;
}

.root .firstMenu .content .closeChevronDown {
  margin-left: 1px;
}

.root .firstMenu .tmpMenuSetOptimizeSummary {
  margin-top: 16px;
  margin-bottom: 4px;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--figma-color-sideMenuHover);
}

.root .firstMenu .tmpMenuSetOptimizeSummary .link {
  font-size: 12px;
  margin-top: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.root .firstMenu .tmpMenuSetOptimizeSummary .link a {
  color: #2f80ed;
}

.root .secondRoot {
  flex: 1;
  min-height: 0;
  overflow: auto;
}

.root .secondRoot .secondMenu {
  margin: 16px 0;
}

.root .secondRoot .secondMenu .divider {
  margin-bottom: 8px;
}

.root .thirdRoot {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
}

.root .thirdRoot:hover {
  background-color: var(--figma-color-sideMenuHover);
  cursor: pointer;
}

.root .thirdRoot .chevron {
  display: flex;
  align-items: center;
  height: 50px;
  padding-right: 15px;
}

@media print {
  .root {
    display: none;
  }
}
